export default function () {
  return {
    widgets: [],
    dashboards: [],
    selectedDashboard: null,
    selectedDashboardGridLayout: null,
    selectedDashboardGridLayouts: null,
    brandColors: null,
    backgroundStyle: null,
    fallbackDashboard: null,
    keycloakClientRoles: [],
    activeWidgets: [],
    groups: [],
    userDeclinedNewRealm: false
  }
}
