export function storeNotifications (state, notifications) {
  state.notifications = notifications
}

export const pushNotification = (state, notification) => {
  if (state.notifications && state.notifications.length > 0) {
    state.notifications = [
      notification,
      ...state.notifications
    ]
  } else {
    state.notifications = [notification]
  }
}

export const setDismissedNotification = (state, set) => {
  state.dismissedNotification = set
}
