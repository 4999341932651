import { createI18n } from 'vue-i18n'
import messages from 'src/i18n'
import { Quasar } from 'quasar'
import { boot } from 'quasar/wrappers'
import quasarLangDa from 'quasar/lang/da'
// You'll need to create the src/i18n/index.js/.ts file too

let i18n

// Allows awaiting that i18n has been fully loaded
// This is needed in keycloak.js
let i18nLoadedResolve
const i18nLoaded = new Promise(resolve => {
  i18nLoadedResolve = resolve
})

export default boot(async ({ app }) => {
  const locale = Quasar.lang.getLocale()

  const setQuasarLang = async (locale) => {
    const languageCode = locale.split('-')[0]

    switch (languageCode) {
      case 'da':
        Quasar.lang.set(quasarLangDa)
        break
      case 'en':
        // defaults to en-us anyway, so no need to set explicitly
        break
      default:
        console.info(`Quasar translation not supported for locale ${locale}`)
        break
    }
  }

  const setAppI18nLocale = async (locale) => {
    const languageCode = locale.split('-')[0]

    i18n = createI18n({
      locale: 'en',
      fallbackLocale: 'en',
      messages
    })

    i18n.global.locale = Object.keys(i18n.global.messages).find(l => l === locale || l === languageCode)

    app.use(i18n)
  }

  setQuasarLang(locale)

  setAppI18nLocale(locale)

  i18nLoadedResolve()
})

export { i18n, i18nLoaded }
