import axios from 'axios'
import axiosRetry from 'axios-retry'
import { getToken } from '../../boot/keycloak'

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay
})

const dashboardApiUrl = process.env.dashboardApiUrl

export const addNotification = async ({ commit }, notification) => {
  commit('pushNotification', notification)
}

export async function fetchNotifications ({ commit }, realm) {
  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  const response = await axios.get(`${dashboardApiUrl}/Realm/${realm}/Notification`, config)

  commit('storeNotifications', response.data)
}
