const routes = [
  {
    path: '/:realm',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ 'pages/Home.vue')
      },
      {
        path: 'dashboard/:id',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ 'pages/Dashboard.vue'),
        props: true
      },
      {
        path: 'dashboardEdit/:dashboardId',
        name: 'dashboardEdit',
        component: () => import(/* webpackChunkName: "dashboardEdit" */ 'pages/DashboardEdit.vue'),
        props: true,
        meta: {
          roles: ['dashboard_admin']
        }
      },
      {
        path: 'dashboards',
        name: 'dashboardList',
        component: () => import(/* webpackChunkName: "dashboardList" */ 'pages/DashboardList.vue'),
        meta: {
          roles: ['dashboard_admin']
        }
      },
      {
        path: 'no-dashboard',
        name: 'noDashboard',
        component: () => import(/* webpackChunkName: "404" */ 'pages/Error404.vue')
      },
      {
        path: '*',
        name: 'notfound',
        component: () => import(/* webpackChunkName: "404" */ 'pages/Error404.vue')
      }
    ]
  },

  {
    path: '/pwaStartPage',
    name: 'pwaStartPage',
    component: () => import(/* webpackChunkName: "PwaStartPage" */ 'pages/PwaStartPage.vue')
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
