import { register } from 'register-service-worker'
import { Notify } from 'quasar'
import { i18n } from '../src/boot/i18n'

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

register(process.env.SERVICE_WORKER_FILE, {
  // The registrationOptions object will be passed as the second argument
  // to ServiceWorkerContainer.register()
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter

  // registrationOptions: { scope: './' },

  ready (/* registration */) {
    // console.log('Service worker is active.')
  },

  registered (/* registration */) {
    // console.log('Service worker has been registered.')
  },

  cached (/* registration */) {
    // console.log('Content has been cached for offline use.')
  },

  updatefound (/* registration */) {
    // console.log('New content is downloading.')
  },

  updated (registration) {
    // console.log('New content is available; please refresh.')

    // Here we make a notification when a new service worker is available.
    // The user can choose to wait but otherwise it uses the new service worker.

    let seconds = 30

    const notify = Notify.create({
      message: i18n.global.tc('newContentMessage', seconds),
      group: false,
      color: 'primary',
      timeout: seconds * 1000,
      actions: [
        {
          label: i18n.global.t('updateLater'),
          color: 'white',
          handler: () => { }
        },
        {
          label: i18n.global.t('updateNow'),
          color: 'secondary',
          handler: () => {
            const waiting = registration?.waiting
            if (waiting) {
              waiting.addEventListener('statechange', event => {
                if (event?.target?.state === 'activated') {
                  window.location.reload()
                }
              })
              waiting.postMessage({ type: 'SKIP_WAITING' })
            } else {
              window.location.reload()
            }
          }
        }
      ],
      onDismiss: () => {
        clearTimeout(timer)
      }
    })

    const timer = setInterval(() => {
      seconds--

      if (seconds > 0) {
        notify({
          message: i18n.global.tc('newContentMessage', seconds)
        })
      } else {
        clearInterval(timer)
        const waiting = registration?.waiting
        if (waiting) {
          waiting.addEventListener('statechange', event => {
            if (event?.target?.state === 'activated') {
              window.location.reload()
            }
          })
          waiting.postMessage({ type: 'SKIP_WAITING' })
        } else {
          window.location.reload()
        }
      }
    }, 1000)
  },

  offline () {
    // console.log('No internet connection found. App is running in offline mode.')
  },

  error (/* err */) {
    // console.error('Error during service worker registration:', err)
  }
})
