import { i18n } from '../../boot/i18n'

export const getDashboards = ({ dashboards }) => dashboards

export const getSelectedDashboard = ({ selectedDashboard }) => selectedDashboard

export const getSelectedDashboardGridLayouts = ({ selectedDashboardGridLayouts }) => selectedDashboardGridLayouts

export const getBrandColors = ({ brandColors }) => brandColors

export const getBackgroundStyle = ({ backgroundStyle }) => backgroundStyle

export const getKeycloakClientRoles = ({ keycloakClientRoles }) => keycloakClientRoles

export const getFallbackDashboard = ({ fallbackDashboard }) => fallbackDashboard

export const getDashboardIdsAndNames = ({ dashboardIdsAndNames }) => dashboardIdsAndNames

export const getActiveWidgets = ({ activeWidgets }) => {
  if (activeWidgets) {
    const widgets = activeWidgets.map(w => {
      return {
        ...w,
        localizedName: i18n.global.t(w.localizationKey)
      }
    })
    return widgets.sort((a, b) => {
      return a.localizedName.localeCompare(b.localizedName)
    })
  } else {
    return []
  }
}

export const getGroups = ({ groups }) => groups

export const getUserDeclinedNewRealm = ({ userDeclinedNewRealm }) => userDeclinedNewRealm
