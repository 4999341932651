import axios from 'axios'
import axiosRetry from 'axios-retry'
import { colors, setCssVar, getCssVar } from 'quasar'
import { getToken, keycloakInstance } from '../../boot/keycloak'
import { openDB } from 'idb'

const dashboardApiUrl = process.env.dashboardApiUrl
const superAdminApiUrl = process.env.superAdminApiUrl

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay
})

const getIDBWithStore = async (dbName, storeName) => {
  let version = 1
  let db
  if ((await window.indexedDB.databases()).map(db => db.name).includes(dbName)) {
    db = await openDB(dbName)
    if (!db.objectStoreNames.contains(storeName)) {
      version = db.version + 1
      db = null
    }
  }

  if (!db) {
    db = await openDB(dbName, version, {
      upgrade (db) {
        db.createObjectStore(storeName)
      }
    })
  }

  return db
}

const getDashboardsFromIDB = async () => {
  try {
    const storeName = `Dashboards_${keycloakInstance.realm}`

    const db = await getIDBWithStore('DashboardDb', storeName)

    return await db.getAll(storeName)
  } catch (error) {
    console.warn('Unable to get dashboards from IDB', error)
    return null
  }
}

const putDashboardsToIDB = async (dashboards) => {
  try {
    const storeName = `Dashboards_${keycloakInstance.realm}`

    const db = await getIDBWithStore('DashboardDb', storeName)

    dashboards.forEach(dashboard => {
      if (dashboard?.id) {
        db.put(storeName, JSON.parse(JSON.stringify(dashboard)), dashboard.id)
      }
    })
  } catch (error) {
    console.warn('Unable to put dashboards to IDB', error)
  }
}

const deleteDashboardFromIDB = async (dashboardId) => {
  try {
    const storeName = `Dashboards_${keycloakInstance.realm}`

    const db = await getIDBWithStore('DashboardDb', storeName)

    db.delete(storeName, dashboardId)
  } catch (error) {
    console.warn('Unable to delete dashboard from IDB', error)
  }
}

export const fetchDashboards = async ({ commit }) => {
  let dashboards = await getDashboardsFromIDB()

  if (dashboards && dashboards.length > 0) {
    commit('storeDashboards', dashboards)
  }

  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  const response = await axios.get(`${dashboardApiUrl}/Dashboards`, config)

  dashboards = response.data || []

  putDashboardsToIDB(dashboards)

  commit('storeDashboards', response.data || [])
}

export const saveDashboard = async ({ commit }, dashboard) => {
  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  if (dashboard.id) {
    putDashboardsToIDB([dashboard])
    await axios.put(`${dashboardApiUrl}/Dashboards`, dashboard, config)
  } else {
    const response = await axios.post(`${dashboardApiUrl}/Dashboards`, dashboard, config)
    dashboard = response.data
    putDashboardsToIDB([dashboard])
  }

  commit('addOrUpdateDashboard', dashboard)
}

export const deleteDashboard = async ({ commit }, dashboardId) => {
  deleteDashboardFromIDB(dashboardId)

  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  await axios.delete(`${dashboardApiUrl}/Dashboards/${dashboardId}`, config)
  commit('removeDashboard', dashboardId)
}

export const selectDashboard = async ({ commit }, dashboard) => {
  commit('storeSelectedDashboard', dashboard)
}

export const selectDashboardById = async ({ state, commit }, dashboardId) => {
  const thisDashboard = state.dashboards.find((dashboard) => {
    return dashboard.id === dashboardId
  })

  commit('storeSelectedDashboard', thisDashboard)
}

export const deselectDashboard = async ({ commit }) => {
  commit('removeSelectedDashboard')
}

export const addWidgetToDashboard = async ({ state, commit }, { dashboard, widget }) => {
  const updatedDashboard = JSON.parse(JSON.stringify(dashboard))

  updatedDashboard.widgets.push(widget)

  await saveDashboard({ commit }, updatedDashboard)

  if (state.selectedDashboard && state.selectedDashboard.id === dashboard.id) {
    selectDashboard({ commit }, updatedDashboard)
  }
}

export const removeWidgetFromDashboard = async ({ state, commit }, { dashboard, widget }) => {
  const updatedDashboard = JSON.parse(JSON.stringify(dashboard))

  const widgetIndex = updatedDashboard.widgets.findIndex(w => w.widgetId === widget.widgetId && w.widgetType === widget.widgetType)

  if (widgetIndex > -1) {
    updatedDashboard.widgets.splice(widgetIndex, 1)

    await saveDashboard({ commit }, updatedDashboard)

    if (state.selectedDashboard && state.selectedDashboard.id === dashboard.id) {
      selectDashboard({ commit }, updatedDashboard)
    }
  }
}

export const updateWidgetLayoutOnDashboard = async ({ state, commit }, { dashboard, layoutType, layout }) => {
  const updatedDashboard = JSON.parse(JSON.stringify(dashboard))
  const widgets = updatedDashboard.widgets
  const updatedLayoutWidgets = layout.map(({ i, x, y, w, h, updateCounter, isBeingEdited, isDraggable, isResizable, ...rest }) => {
    const newProp = {
      ...rest, column: x, row: y, width: w, height: h, isLocked: !isDraggable
    }
    return newProp
  })

  widgets.forEach(widget => {
    const sameWidget = updatedLayoutWidgets.find(w => {
      return w.widgetId === widget.widgetId && w.widgetType === widget.widgetType
    })
    if (sameWidget) {
      widget[layoutType] = sameWidget
    }
  })

  updatedDashboard.widgets = widgets
  const activeLayouts = updatedDashboard.activeLayouts ?? []
  if (!(activeLayouts.includes(layoutType))) {
    activeLayouts.push(layoutType)
  }

  updatedDashboard.activeLayouts = activeLayouts

  // if dashboard hasn't changed, no need to save
  if (JSON.stringify(updatedDashboard) !== JSON.stringify(dashboard)) {
    saveDashboard({ commit }, updatedDashboard)
  }

  if (state.selectedDashboard && state.selectedDashboard.id === dashboard.id) {
    selectDashboard({ commit }, updatedDashboard)
  }
}

export const updateWidgetLayoutByRemovingLayoutTypeOnDashboard = async ({ state, commit }, { dashboard, layoutTypeToRemove, layout }) => {
  const updatedDashboard = JSON.parse(JSON.stringify(dashboard))
  const widgets = updatedDashboard.widgets
  const updatedLayoutWidgets = layout.map(({ i, x, y, w, h, updateCounter, isBeingEdited, isDraggable, isResizable, ...rest }) => {
    const newProp = {
      ...rest, column: x, row: y, width: w, height: h, isLocked: !isDraggable
    }
    return newProp
  })

  widgets.forEach(widget => {
    const sameWidget = updatedLayoutWidgets.find(w => {
      return w.widgetId === widget.widgetId && w.widgetType === widget.widgetType
    })
    if (sameWidget) {
      widget[layoutTypeToRemove] = null
    }
  })

  updatedDashboard.widgets = widgets
  const activeLayouts = updatedDashboard.activeLayouts ?? []
  if (activeLayouts.includes(layoutTypeToRemove)) {
    activeLayouts.splice(activeLayouts.indexOf(layoutTypeToRemove), 1)
  }

  updatedDashboard.activeLayouts = activeLayouts

  // if dashboard hasn't changed, no need to save
  if (JSON.stringify(updatedDashboard) !== JSON.stringify(dashboard)) {
    saveDashboard({ commit }, updatedDashboard)
  }

  if (state.selectedDashboard && state.selectedDashboard.id === dashboard.id) {
    selectDashboard({ commit }, updatedDashboard)
  }
}

const setBrandColors = (brandColors) => {
  Object.entries(brandColors).forEach(c => {
    const [colorName, colorValue] = c

    // TODO: currently text color on buttons and other ui elements is white be default,
    // if the brand color is light, then legibility of the text can be really bad.
    // We may detect if the color is light or dark and apply some tricks accordingly.
    // It may turn out to be quite a challenge to get it working on the web components though,
    // since shadow piercing having been removed in chrome (https://developers.google.com/web/updates/2017/10/remove-shadow-piercing)
    if (colors.brightness(colorValue) > 128) {
      // light
    } else {
      // dark
    }

    setCssVar(colorName, colorValue, document.documentElement)
  })
}

export const fetchBrandColors = async ({ commit }) => {
  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  const response = await axios.get(`${dashboardApiUrl}/BrandColors`, config)

  const brandColors = response.data || {
    primary: getCssVar('primary').toLowerCase(),
    secondary: getCssVar('secondary').toLowerCase(),
    accent: getCssVar('accent').toLowerCase(),
    dark: getCssVar('dark').toLowerCase(),
    positive: getCssVar('positive').toLowerCase(),
    negative: getCssVar('negative').toLowerCase(),
    info: getCssVar('info').toLowerCase(),
    warning: getCssVar('warning').toLowerCase()
  }

  commit('storeBrandColors', brandColors)

  setBrandColors(brandColors)
}

export const saveBrandColors = async ({ commit }, brandColors) => {
  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  await axios.put(`${dashboardApiUrl}/BrandColors`, brandColors, config)

  commit('storeBrandColors', brandColors)

  setBrandColors(brandColors)
}

export const fetchBackgroundStyle = async ({ commit }) => {
  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  const response = await axios.get(`${dashboardApiUrl}/BackgroundStyle`, config)

  const backgroundStyle = response.data || {
    color: '#ffffff'
  }

  commit('storeBackgroundStyle', backgroundStyle)
}

export const saveBackgroundStyle = async ({ state, commit }, { backgroundStyle, imageFile }) => {
  // if there are no changes, no reason to save
  if (!imageFile && JSON.stringify(backgroundStyle) === JSON.stringify(state.backgroundStyle)) {
    return
  }

  // ensure there is an array, so we don't have to put guards before accessing property
  backgroundStyle.previousImageIds = backgroundStyle.previousImageIds || []

  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  await axios.put(`${dashboardApiUrl}/BackgroundStyle`, backgroundStyle, config)

  if (imageFile && (backgroundStyle.imageId === null || backgroundStyle.imageId !== state.backgroundStyle.imageId) && !backgroundStyle.previousImageIds.some(id => id === backgroundStyle.imageId)) {
    const formData = new FormData()
    formData.append('file', imageFile)
    const response = await axios.post(`${dashboardApiUrl}/BackgroundStyle/UploadImage`, formData, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (state.backgroundStyle.imageId) {
      backgroundStyle.previousImageIds.push(state.backgroundStyle.imageId)
    }
    backgroundStyle.imageId = response.data
  } else if (!backgroundStyle.imageId && state.backgroundStyle.imageId) {
    backgroundStyle.previousImageIds.push(state.backgroundStyle.imageId)
  }

  // ensure previous image ids are unique and that the current image is not among them
  const uniquePreviousImageIds = new Set(backgroundStyle.previousImageIds)
  uniquePreviousImageIds.delete(backgroundStyle.imageId)
  backgroundStyle.previousImageIds = [...uniquePreviousImageIds]

  commit('storeBackgroundStyle', backgroundStyle)
}

export const deleteBackgroundStyleImage = async ({ commit }, imageId) => {
  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  await axios.delete(`${dashboardApiUrl}/BackgroundStyle/DeleteImage/${imageId}`, config)

  commit('removeBackgroundStyleImage', imageId)
}

export const fetchKeycloakClientRoles = async ({ commit }, { realm, clientName }) => {
  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  const response = await axios.get(`${dashboardApiUrl}/KeycloakRoles/${realm}/${clientName}`, config)

  commit('storeKeycloakClientRoles', response.data || [])
}

export const fetchFallbackDashboard = async ({ commit }) => {
  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }
  const response = await axios.get(`${dashboardApiUrl}/FallbackDashboard`, config)
  const fallbackDashboard = response.data || {
    fallbackDashboardId: null
  }

  commit('storeFallbackDashboard', fallbackDashboard)
}

export const saveFallbackDashboard = async ({ commit }, { fallbackDashboard }) => {
  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  if (!fallbackDashboard.id) {
    await axios.post(`${dashboardApiUrl}/FallbackDashboard`, fallbackDashboard, config)
  } else {
    await axios.put(`${dashboardApiUrl}/FallbackDashboard`, fallbackDashboard, config)
  }

  commit('storeFallbackDashboard', fallbackDashboard)
}

export const fetchActiveWidgets = async ({ commit }) => {
  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  const response = await axios.get(`${superAdminApiUrl}/Widgets`, config)

  commit('storeActiveWidgets', response.data)
}

export const fetchGroups = async ({ commit }) => {
  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  const response = await axios.get(`${dashboardApiUrl}/Group`, config)

  commit('storeGroups', response.data || [])
}

export const saveGroup = async ({ commit }, group) => {
  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  if (group.id) {
    await axios.put(`${dashboardApiUrl}/Group`, group, config)
  } else {
    const response = await axios.post(`${dashboardApiUrl}/Group`, group, config)

    group = response.data
  }

  commit('addOrUpdateGroup', group)
}

export const deleteGroup = async ({ commit }, groupId) => {
  const config = {
    headers: { Authorization: `Bearer ${await getToken()}` }
  }

  const dashboardGroupConfig = {
    headers: { Authorization: `Bearer ${await getToken()}` },
    params: {
      groupId
    }
  }

  const dashboardResponse = await axios.get(`${dashboardApiUrl}/Dashboards`, dashboardGroupConfig)

  for (const dashboard of dashboardResponse.data) {
    const dashboardWithoutGroup = {
      ...dashboard,
      groupId: null
    }
    await axios.put(`${dashboardApiUrl}/Dashboards`, dashboardWithoutGroup, config)
    commit('addOrUpdateDashboard', dashboardWithoutGroup)
  }

  await axios.delete(`${dashboardApiUrl}/Group/${groupId}`, config)
  commit('removeGroup', groupId)
}

export const doesKeycloakRealmExist = async ({ commit }, { realm }) => {
  try {
    const response = await axios.get(`${dashboardApiUrl}/KeycloakRealms/${realm}/exist`)

    return response.data
  } catch (error) {
    return false
  }
}
