const dashboardApiUrl = process.env.dashboardApiUrl

export function storeWidgets (state, widgets) {
  state.widgets = (widgets || []).map(w => {
    return {
      ...w,
      isBeingEdited: false,
      isDraggable: !w.isLocked,
      isResizable: !w.isLocked,
      updateCounter: 0
    }
  })
}

export function setNewGridItem (state, newWidget) {
  state.widgets.push(newWidget)
}

export const storeDashboards = (state, dashboards) => {
  const dashboardsWithActiveLayouts = dashboards.map(d => {
    return {
      ...d,
      activeLayouts: d?.activeLayouts ?? ['lg'],
      isDashboard: true
    }
  })

  state.dashboards = dashboardsWithActiveLayouts
}

export const addOrUpdateDashboard = (state, dashboard) => {
  const index = dashboard.id ? state.dashboards.findIndex(d => d.id === dashboard.id) : -1

  if (index > -1) {
    state.dashboards[index] = {
      ...dashboard,
      isDashboard: true
    }
  } else {
    state.dashboards.push({
      ...dashboard,
      isDashboard: true
    })
  }
}

export const removeDashboard = (state, dashboardId) => {
  const index = state.dashboards.findIndex(d => d.id === dashboardId)

  if (index > -1) {
    state.dashboards.splice(index, 1)
  }
}

export const removeSelectedDashboard = (state) => {
  state.selectedDashboard = null
  state.selectedDashboardGridLayouts = null
}

export const storeSelectedDashboard = (state, selectedDashboard) => {
  if (selectedDashboard?.hideBackground === true) {
    setBackgroundStyle({})
  } else {
    setBackgroundStyle(state.backgroundStyle)
  }

  state.selectedDashboard = selectedDashboard
  state.selectedDashboardGridLayouts = {}
  const types = ['xl', 'lg', 'md', 'sm', 'xs']
  let widgetsHasLayout = false
  types.forEach((type) => {
    if (selectedDashboard.widgets.some(w => w[type] !== null && w[type] !== undefined)) {
      widgetsHasLayout = true
      state.selectedDashboardGridLayouts[type] = selectedDashboard.widgets.map(w => {
        if (w[type]) {
          return {
            widgetId: w.widgetId,
            widgetType: w.widgetType,
            i: `${selectedDashboard.id}_${w.widgetType}_${w.widgetId}`,
            x: w[type]?.column,
            y: w[type]?.row,
            w: w[type]?.width,
            h: w[type]?.height,
            isBeingEdited: false,
            isDraggable: !w[type]?.isLocked,
            isResizable: !w[type]?.isLocked,
            isHidden: w[type]?.isHidden,
            updateCounter: 0,
            overflow: w.overflow ? w.overflow : 'auto'
          }
        } else {
          return null
        }
      })
    }
  })

  // Code to make it work with dashboards from before we had layouts
  if (!widgetsHasLayout) {
    if (selectedDashboard.widgets.length > 0) {
      state.selectedDashboardGridLayouts.lg = selectedDashboard.widgets.map(w => {
        return {
          widgetId: w.widgetId,
          widgetType: w.widgetType,
          i: `${selectedDashboard.id}_${w.widgetType}_${w.widgetId}`,
          x: w.column,
          y: w.row,
          w: w.width,
          h: w.height,
          isBeingEdited: false,
          isDraggable: !w.isLocked,
          isResizable: !w.isLocked,
          updateCounter: 0,
          overflow: 'auto'
        }
      })
    }
  }
}

export const storeBrandColors = (state, brandColors) => {
  state.brandColors = brandColors
}

export const setBackgroundStyle = (backgroundStyle) => {
  if (backgroundStyle != null && backgroundStyle !== undefined) {
    const root = document.documentElement
    root.style.setProperty('--app-bg-color', backgroundStyle.color)
    root.style.setProperty('--app-bg-url', backgroundStyle.image ? `url("${backgroundStyle.image.url}")` : null)
    root.style.setProperty('--app-bg-size', backgroundStyle.size)
    root.style.setProperty('--app-bg-position', backgroundStyle.position)
    root.style.setProperty('--app-bg-repeat', backgroundStyle.repeat)
  }
}

export const storeBackgroundStyle = (state, backgroundStyle) => {
  const defaults = {
    color: 'white',
    size: 'cover',
    position: 'center center',
    repeat: 'no-repeat'
  }

  backgroundStyle = {
    ...defaults,
    ...backgroundStyle,
    image: backgroundStyle.imageId
      ? {
          id: backgroundStyle.imageId,
          url: `${dashboardApiUrl}/BackgroundStyle/DownloadImage/${backgroundStyle.imageId}`
        }
      : null,
    previousImages: backgroundStyle.previousImageIds
      ? backgroundStyle.previousImageIds.map(id => {
        return { id, url: `${dashboardApiUrl}/BackgroundStyle/DownloadImage/${id}` }
      })
      : []
  }

  setBackgroundStyle(backgroundStyle)

  state.backgroundStyle = backgroundStyle
}

export const removeBackgroundStyleImage = (state, imageId) => {
  if (state.backgroundStyle.imageId === imageId) {
    state.backgroundStyle.imageId = null
    state.backgroundStyle.image = null
  }

  if (state.backgroundStyle.previousImageIds) {
    const index = state.backgroundStyle.previousImageIds.findIndex(id => id === imageId)

    if (index > -1) {
      state.backgroundStyle.previousImageIds.splice(index, 1)
      state.backgroundStyle.previousImages.splice(index, 1)
    }
  }
}

export const storeKeycloakClientRoles = (state, keycloakClientRoles) => {
  state.keycloakClientRoles = keycloakClientRoles
}

export const storeFallbackDashboard = (state, fallbackDashboard) => {
  state.fallbackDashboard = fallbackDashboard
}

export const storeActiveWidgets = (state, activeWidgets) => {
  state.activeWidgets = activeWidgets
}

export const storeGroups = (state, groups) => {
  state.groups = groups.map(g => {
    return {
      ...g,
      isGroup: true
    }
  })
}

export const addOrUpdateGroup = (state, group) => {
  const index = group.id ? state.groups.findIndex(g => g.id === group.id) : -1

  if (index > -1) {
    state.groups[index] = {
      ...group,
      isGroup: true
    }
  } else {
    state.groups.push({
      ...group,
      isGroup: true
    })
  }
}

export const removeGroup = (state, groupId) => {
  const index = state.groups.findIndex(g => g.id === groupId)

  if (index > -1) {
    state.groups.splice(index, 1)
  }
}

export const setUserDeclinedNewRealm = (state, userDeclinedNewRealm) => {
  state.userDeclinedNewRealm = userDeclinedNewRealm
}
