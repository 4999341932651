import routes from '../router/routes'

// access to routes can be restricted by specifying which allowed roles in the meta.roles property of the route
// note that only child routes of the 'realm' route are checked
const rolesByRoute = routes.find(r => r.path === '/:realm').children.reduce((a, x) => ({ ...a, [x.name]: x.meta && x.meta.roles ? x.meta.roles : [] }), {})

export const hasRouteAccess = (routeName, $keycloak) => {
  const roles = rolesByRoute[routeName]

  if (roles && roles.length > 0) {
    return roles.some(r => $keycloak?.hasResourceRole(r))
  }

  return true
}

export default {
  methods: {
    hasRouteAccess (routeName) {
      return hasRouteAccess(routeName, this.$keycloak)
    }
  }
}
