import { store } from 'quasar/wrappers'
import { createStore } from 'vuex'

import dashboard from './dashboard'
import notification from './notification'

export default store(function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      dashboard,
      notification
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING
  })

  return Store
})
