import { reactive, watch } from 'vue'
import { Cookies, LocalStorage, SessionStorage, AppVisibility, Notify } from 'quasar'
import { i18n, i18nLoaded } from '../boot/i18n'
import { hasRouteAccess } from '../mixins/keycloak-mixin'
import { boot } from 'quasar/wrappers'
import Keycloak from 'keycloak-js'
import axios from 'axios'

const cookieOptions = {
  sameSite: 'Lax',
  domain: window.location.hostname.replace(/^[\w-]*\./, '')
}

// for ensuring keycloak initialization has finished before doing routing
let initResolve, initReject
const initPromise = new Promise((resolve, reject) => {
  initResolve = resolve
  initReject = reject
})

export let keycloakInstance

// more info on params: https://quasar.dev/quasar-cli/cli-documentation/boot-files#Anatomy-of-a-boot-file
export default boot(async ({ router, app }) => {
  // ensure that user is authenticated before routing
  router.beforeEach(async (to, from, next) => {
    // wait for keycloak init before continuing routing
    // this helps ensuring that page refreshes (F5) and deep links work
    await initPromise.catch(reason => {
      console.debug(reason)
    })

    // check that the user has access to the route
    if (app.config.globalProperties.$keycloak && !hasRouteAccess(to.name, app.config.globalProperties.$keycloak)) {
      // if the user was already logged in
      if (from.params.realm) {
        // show not found page
        next({ name: 'notfound', params: { 0: to.path.replace(`/${to.params.realm}/`, '') } })
        // this ensures user doesn't have to click "Go back" button twice
        router.back()
      } else {
        // otherwise show the home page
        next({ name: 'home', params: to.params })
      }
      return
    }

    if (document.referrer !== location.href) {
      // if destination route has realm parameter, but keycloak authentication has not been performed
      // or if realm paremeter is different between source and destination route (i.e. user is trying to access another realm)
      if ((to.params.realm && !app.config.globalProperties.$keycloak) || (from.params.realm && to.params.realm !== from.params.realm && app.config.globalProperties.$keycloak)) {
        // refresh the page will ensure this boot plugin is executed again
        reloadToRestartLoginFlow()
      }
    }

    // keycloak v12 appends state parameters to the route, so we need to manually remove it with some regex magic
    if (from.path === '/' && to.path.indexOf('&state=') > -1) {
      next(to.path.replace(/(&state=.*?)(\/|$)/, ''))
    } else {
      next()
    }
  })

  // tenants are known as realms in keycloak
  // we determine the realms based on the route
  // the following urls can be used when accessing the realm
  // https://c3ng.rm-group.dk/#/<REALM> or https://c3ng.rm-group.dk/#/<REALM>/ or https://c3ng.rm-group.dk/#<REALM> or https://c3ng.rm-group.dk/#<REALM>/

  // we use router.match to get realm param
  // for that we need the path, split is to avoid query string
  const path = location.href.split(/[?&]/)[0].replace(/^(?:\/\/|[^/]+)*\/(?:#\/)?/, '')

  // we allow for an idp hint to be given, by specifying it after a @ symbol after the realm name e.g. https://c3ng.rm-group.dk/#/<REALM>@<IDP_HINT>
  // the idp hint allows keycloak to redirect directly to a brokered idp instead of showing the keycloak login page
  let idpHint = path.split('@')[1]?.split('/')?.at(0)

  let realm = router.resolve(path.split('@')[0]).params.realm

  if (!realm && LocalStorage.getItem('realm')) {
    realm = LocalStorage.getItem('realm')

    if (LocalStorage.getItem('idpHint')) {
      idpHint = LocalStorage.getItem('idpHint')
    }
  }

  if (!realm) {
    initReject('No realm')
    return
  }

  const keycloakOptions = {
    url: process.env.keycloakUrl, realm, clientId: process.env.keycloakClientId, onLoad: 'login-required', idpHint
  }

  const minValiditySeconds = 10

  const offlineTokenKeyName = `offline_access_token_${realm}`

  const reactiveKeycloak = reactive({
    realm: null,
    hasResourceRole: () => {},
    token: null,
    tokenParsed: null,
    resourceAccess: null,
    logout: () => {}
  })

  const keycloak = await createKeycloakInstance(keycloakOptions, offlineTokenKeyName)

  if (keycloak.authenticated) {
    clearReloadToRestartLoginFlowCount()

    initResolve()

    setInterval(async () => {
      if (!keycloak.refreshToken) {
        // if there is no refresh token, there is no need to try and refresh it
        // reloading the page will restart the authentication flow
        reloadToRestartLoginFlow()
      }

      await updateTokenIfExpired()
    }, 10000)
  } else {
    console.info('Not authenticated')
    initReject('Not authorized')
    reloadToRestartLoginFlow()
  }

  const updateTokenIfExpired = async () => {
    const isExpired = keycloak.isTokenExpired(minValiditySeconds)
    if (isExpired) {
      await updateToken()
    }
  }

  const updateToken = async () => {
    try {
      const refreshed = await keycloak.updateToken(minValiditySeconds)
      if (refreshed) {
        console.info('Token refreshed!')
        // update the cookie
        Cookies.set('jwt', keycloak.token, { ...cookieOptions, expires: new Date(keycloak.tokenParsed.exp * 1000) })
        if (Cookies.get('jwt') !== keycloak.token) {
          Cookies.set('jwt', keycloak.refreshToken, { ...cookieOptions, expires: new Date(keycloak.refreshTokenParsed.exp * 1000) })
        }
      } else {
        console.info(`Token not refreshed, valid for ${Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000)} seconds`)

        if (await checkKeycloakLiveness()) {
          reloadToRestartLoginFlow()
        }
      }
    } catch (error) {
      console.error('Failed to refresh token', error)

      if (await checkKeycloakLiveness()) {
        reloadToRestartLoginFlow()
      }
    }
  }

  keycloak.onAuthSuccess = assignKeycloakValues
  keycloak.onAuthRefreshSuccess = assignKeycloakValues

  function assignKeycloakValues () {
    reactiveKeycloak.realm = keycloak.realm
    reactiveKeycloak.hasResourceRole = keycloak.hasResourceRole
    reactiveKeycloak.token = keycloak.token
    reactiveKeycloak.resourceAccess = keycloak.resourceAccess
    reactiveKeycloak.tokenParsed = keycloak.tokenParsed
    reactiveKeycloak.logout = keycloak.logout
  }

  // If the app has been in the background check if token need to be updated
  watch(() => AppVisibility.appVisible, async val => {
    val ? await updateTokenIfExpired() : console.info('App went in the background')
  })

  assignKeycloakValues()

  app.config.globalProperties.$keycloak = reactiveKeycloak
  keycloakInstance = keycloak
})

// function used for ensuring that keycloak is up and running before restarting the login flow
// this can help prevent offline users being logged out when keycloak is temporarily unavailable
const checkKeycloakLiveness = async () => {
  try {
    const response = await axios.get(`${process.env.dashboardApiUrl}/KeycloakHealth`)
    if (response.status === 200) {
      return response?.data?.status === 'UP'
    } else {
      const error = new Error(response.status)
      error.response = response
      throw error
    }
  } catch (error) {
    console.debug('Error checking keycloak liveness', error)
    return false
  }
}

// To avoid situations where the page reloads endlessly during login
// We have implemented a function that keeps track of login reloads during the session
// It shows a notification if max number of reloads were exeeded
const reloadToRestartLoginFlow = async () => {
  const count = parseInt(SessionStorage.getItem('reloadToRestartLoginFlowCount')) || 0

  if (count < 5) {
    SessionStorage.set('reloadToRestartLoginFlowCount', count + 1)
    window.location.reload()
  } else {
    await i18nLoaded
    const notify = Notify.create({
      message: i18n.global.t('tooManyReloadsDuringLogin'),
      type: 'negative',
      timeout: 0,
      actions: [
        { icon: 'close', color: 'white', round: true }
      ]
    })
    notify({
      message: i18n.global.t('tooManyReloadsDuringLogin')
    })
  }
}

// Number of reloads are cleared when authentication was successful
const clearReloadToRestartLoginFlowCount = () => {
  SessionStorage.remove('reloadToRestartLoginFlowCount')
}

export const createKeycloakInstance = async (keycloakOptions, offlineTokenKeyName) => {
  let keycloak
  let authenticated = false
  const offlineAccessToken = LocalStorage.getItem(offlineTokenKeyName)

  // If online access token exists in local storage, authenticate using that
  if (offlineAccessToken) {
    try {
      keycloak = new Keycloak(keycloakOptions)
      authenticated = await keycloak.init({ token: offlineAccessToken, refreshToken: offlineAccessToken, idToken: offlineAccessToken, checkLoginIframe: false })

      // Remove offline access token from the local storage if not authenticated
      if (!authenticated) {
        authenticated = false
        LocalStorage.remove(offlineTokenKeyName)
      } else {
        Cookies.set('jwt', keycloak.token, { ...cookieOptions, expires: new Date(keycloak.tokenParsed.exp * 1000) })
        if (Cookies.get('jwt') !== keycloak.token) {
          Cookies.set('jwt', keycloak.refreshToken, { ...cookieOptions, expires: new Date(keycloak.refreshTokenParsed.exp * 1000) })
        }
      }
    } catch (error) {
      authenticated = false
      console.error('Error refreshing offline access token!', error)
      if (await checkKeycloakLiveness()) {
        LocalStorage.remove(offlineTokenKeyName)
      }
    }
  }

  // If the above authentication fails, then try the normal way
  if (!authenticated) {
    try {
      keycloak = new Keycloak(keycloakOptions)
      authenticated = await keycloak.init({ checkLoginIframe: false })

      if (!authenticated) {
        authenticated = await keycloak.login({ idpHint: keycloakOptions.idpHint })
      }

      if (authenticated) {
        // Check for the offline_access role
        if (keycloak.hasResourceRole('offline_access', keycloakOptions.clientId)) {
          if (!keycloak.tokenParsed.scope.split(' ').some(s => s === 'offline_access')) {
            keycloak.clearToken()
            await keycloak.login({ onLoad: keycloakOptions.onLoad, checkLoginIframe: false, scope: 'offline_access' })
          } else {
            LocalStorage.set(offlineTokenKeyName, keycloak.refreshToken)
            Cookies.set('jwt', keycloak.token, { ...cookieOptions, expires: new Date(keycloak.tokenParsed.exp * 1000) })
            if (Cookies.get('jwt') !== keycloak.token) {
              Cookies.set('jwt', keycloak.refreshToken, { ...cookieOptions, expires: new Date(keycloak.refreshTokenParsed.exp * 1000) })
            }
          }
        } else {
          // Use the cookie if the offline access role is not set/configured
          // the cookie approach is especially useful for image download
          Cookies.set('jwt', keycloak.token, { ...cookieOptions, expires: new Date(keycloak.tokenParsed.exp * 1000) })
          if (Cookies.get('jwt') !== keycloak.token) {
            Cookies.set('jwt', keycloak.refreshToken, { ...cookieOptions, expires: new Date(keycloak.refreshTokenParsed.exp * 1000) })
          }
        }
      }
    } catch (error) {
      authenticated = false
      console.error('Error authenticating', error)
    }
  }

  return keycloak
}

/**
 * Returns a promise that resolves the keycloak token when it is available.
 * It is rejected if the keycloak.js boot file was not configured in quasar.conf.js
 */
export const getToken = () => {
  return new Promise((resolve, reject) => {
    if (!keycloakInstance) {
      reject(new Error('keycloakInstance not defined! Please check that keycloak.js was added to the boot array in quasar.conf.js!'))
    }

    if (keycloakInstance.token) {
      resolve(keycloakInstance.token)
    } else {
      watch(keycloakInstance, value => {
        resolve(value.token)
      })
    }
  })
}

// Used for unit testing, in order to mimick that Keycloak has set the token
export const setToken = (token) => {
  return new Promise((resolve, reject) => {
    if (!keycloakInstance) {
      reject(new Error('keycloakInstance not defined! Please check that keycloak.js was added to the boot array in quasar.conf.js!'))
    }

    keycloakInstance.token = token

    resolve()
  })
}
